@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@300;400;600;700&family=Roboto+Mono:wght@400;500&display=swap");

/* font-family: 'Darker Grotesque', sans-serif;
font-family: 'Roboto Mono', monospace; 
font-family: 'Noto Serif SC', serif;
*/

:root {
	--hori-padding: 15%;
	--footer-height: 8rem;
	--breakpoint-lg: 992px;
	--breakpoint-md: 768px;
	--breakpoint-sm: 576px;
}

@media (max-width: 576px) {
	:root {
		--hori-padding: 5%;
	}
}

html,
body {
	margin: 0;
	padding: 0;
}

canvas {
	display: block;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	/* outline: 3px solid #ff0000; */
}

/* margin reset */
body,
h1,
h2,
h3,
h4,
h5,
p {
	margin: 0;
}

/* for footer position, see 
https://www.freecodecamp.org/news/how-to-keep-your-footer-where-it-belongs-59c6aa05c59c/*/

.page-container {
	position: relative;
	min-height: 100vh;
}

.content-container {
	padding: 4rem var(--hori-padding);
	padding-bottom: var(--footer-height);
}

.disable-select {
	/* noselect */
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.disable-drag{
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	/* this doesn't work, use 
	draggable="false" as attribute in img tag */
}

@media (max-width: 576px) {
	.content-container {
		padding-top: 0;
	}
}
